module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"chehalem_hill_vineyard","short_name":"CHV","description":"The portal to better wine at Chehalem Hill","lang":"en","start_url":"/","background_color":"#ccdad1ff","theme_color":"#ccdad1ff","display":"minimal-ui","icon":"src/images/header/chv_logo_maskable_icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5ccde1ea6c179ce6d80e51154166dd9f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
